<template>
  <div>
    <modal-document :id="modalId" :invoice_id="invoice_id"/>
    <modal-pdf id="-invoice-list" :pdfUrl="pdfUrl" />
    <b-sidebar id="add-new-invoice-sidebar" :visible="isAddNewInvoiceSidebarActive" bg-variant="white"
      sidebar-class="sidebar-lg" shadow backdrop no-header right @hidden="resetForm"
      @change="(val) => updateSideBar(val)">
      <template #default="{ hide }">
        <!-- Header -->
        <div class="
            d-flex
            justify-content-between
            align-items-center
            content-sidebar-header
            px-2
            py-1
          ">
          <h5 class="mb-0">
            Payment Detail - {{ invoice.invoice_number }} ({{
              invoice.client_code
            }})
          </h5>

          <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
        </div>

        <!-- Body -->
        <b-overlay :show="loading" spinner-variant="primary" spinner-type="grow" spinner-small rounded="sm">
          <!-- Form -->
          <b-form class="p-2" @submit.prevent="onSubmit" @reset.prevent="resetForm">
            <b-form-group class="" label="應付金額" label-for="total_amount">
              <b-form-input id="total_amount" v-model="invoice.total_amount" disabled />
            </b-form-group>
            <b-form-group class="required" label="支付金額" label-for="payment_amount">
              <b-form-input id="payment_amount" v-model="invoice.payment_amount" />
            </b-form-group>
            <b-form-group class="required" label="支付ITEM" label-for="item"  v-if="false">
              <b-form-input id="item" v-model="invoice.item" />
            </b-form-group>
            <b-form-group class="required" label="收據模版" label-for="receipt_template_id">
              <v-select v-model="invoice.receipt_template_id" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="receiptTemplateOptions" label="name" :reduce="(option) => option.id" :clearable="false">
              </v-select>
            </b-form-group>
            <b-form-group class="required" label="支付方法" label-for="payment_method_id">
              <v-select v-model="invoice.payment_method_id" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="paymentOptions" label="method_name" :reduce="(option) => option.id" :clearable="false">
              </v-select>
            </b-form-group>
            <!-- Description -->
            <b-form-group class="payment_date required" label="支付日期" label-for="payment_date">
              <b-form-datepicker id="payment_date" placeholder="Choose a date" local="en" v-model="invoice.payment_date"
                :date-format-options="{
                  year: 'numeric',
                  month: 'numeric',
                  day: 'numeric',
                }" />
            </b-form-group>
            <b-form-group label="收據">
              <el-upload class="avatar-uploader" list-type="picture-card" ref="upload" :action="uploadUrl"
                :headers="headerObj" name="image_file" :file-list="fileList" multiple :limit="1" :data="invoice"
                :on-change="onChange" :on-remove="toggleUpload" :before-remove="beforeRemove" :auto-upload="false"
                :class="{ hideUpload: !showUpload }">
                <img v-if="invoice.image_path && false" :src="invoice.image_path" class="avatar" />
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </b-form-group>
            


            <b-form-group label="支付備註" label-for="remark">
              <b-form-textarea v-model="invoice.payment_remark" />
            </b-form-group>

            <!-- Form Actions -->

            <div class="d-flex mt-2">
              <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mr-2" type="submit">
                Save 
              </b-button>
              <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" type="button" variant="outline-secondary"
                @click="hide">
                Cancel
              </b-button>
            </div>
          </b-form>
        </b-overlay>
      </template>
    </b-sidebar>

    <invoice-list-filter :user-filter.sync="userFilter" :date-from-filter.sync="dateFromFilter"
      :date-to-filter.sync="dateToFilter" :user-options="userOptions" :client-options="clientOptions"
      :client-filter.sync="clientFilter" :status-filter.sync="statusFilter" :status-options="statusOptions" />
    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col cols="12" class="mb-1">
            <h4>{{invoice_list_name}}</h4>
          </b-col>
          <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
             
            <label>Show</label>
            <v-select v-model="perPage" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="perPageOptions"
              :clearable="false" class="per-page-selector d-inline-block mx-50" />
            <label>entries</label>
          </b-col>

          <!-- Search -->
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input v-model="searchQuery" class="d-inline-block mr-1" placeholder="搜尋..." />
              <b-button variant="primary" v-if="ability.can('create', 'invoices')" :to="{ name: 'invoices-add' }">
                <span class="text-nowrap">新增發票</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>
      <b-overlay :show="tableLoading" spinner-variant="primary" spinner-type="grow" spinner-small rounded="sm">
      <b-table ref="refInvoiceListTable" class="position-relative" :items="fetchInvoices" responsive
        :fields="tableColumns" primary-key="id" :sort-by.sync="sortBy" show-empty empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc">
        <!-- Column: Create Date -->
        <template #cell(created_at)="data">
          {{ getCorrectDateTime(data.item.created_at) }}
        </template>

        <!-- Column: Amount -->
        <template #cell(total_amount)="data">
          {{ getNumberFormat(data.item.total_amount) }}
        </template>
        <!-- Column: Amount -->
        <template #cell(total_paid)="data">
          {{ getNumberFormat(data.item.total_paid) }}
        </template>
        <!-- Column: Total Remain -->
        <template #cell(total_remain)="data">
          {{ getNumberFormat((data.item.total_amount - data.item.total_paid) < 0? 0: (data.item.total_amount - data.item.total_paid)) }}
        </template>

        <!-- Column: Client Code -->
        <template #cell(client)="data">
          <b-link :to="{
            name: 'clients-detail',
            params: { id: data.item.client.id },
          }">{{ data.item.client.client_code }}</b-link>
        </template>

        <template #cell(document)="data">
          <b-button v-ripple.400="'rgba(40, 199, 111, 0.15)'" variant="flat-primary" class="btn-icon"
              target="_blank" @click="downloadDocument(data.item.id)">
              <feather-icon icon="DownloadCloudIcon" />
          </b-button>
      </template>


        <!-- Column: Contract -->
        <template #cell(contract)="data">
          <b-badge class="text-capitalize" v-if="data.item.contract">
            {{ data.item.contract.contract_id }}
          </b-badge>
        </template>
        <!-- Column: IS PAID -->
        <template #cell(is_paid)="data">
          <feather-icon v-if="data.item.total_paid >= data.item.total_amount" icon="CheckIcon" size="18"
            class="mr-50 text-success" />
        </template>
        <!-- Column: IS PAID -->
        <template #cell(is_audit)="data">
          <feather-icon v-if="data.item.is_audit" icon="CheckIcon" size="18" class="mr-50 text-success" />
        </template>
        <!-- Column: Project -->
        <template #cell(project)="data">
          <b-badge class="text-capitalize" v-if="data.item.project">
            {{ data.item.project.project_id }}
          </b-badge>
        </template>

        <template #cell(created_by)="data">
          <span v-if="data.item.user">{{ data.item.user.name }}</span>

        </template>

        <!-- Column: Export -->
        <!--  <template #cell(export)="data">
          <b-button
            v-ripple.400="'rgba(40, 199, 111, 0.15)'"
            variant="flat-success"
            class="btn-icon"
            :to="{ name: 'invoices-preview', params: { id: data.item.id } }"
          >
            <feather-icon icon="FileTextIcon" />
          </b-button>
        </template> -->
        <template #cell(invoice)="data">
          <b-button v-ripple.400="'rgba(40, 199, 111, 0.15)'" variant="flat-primary" class="btn-icon" target="_blank"
            @click="downloadInvoice(data.item.id)">
            <feather-icon icon="FileTextIcon" />
          </b-button>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown variant="link" no-caret :right="$store.state.appConfig.isRTL">
            <template #button-content>
              <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />
            </template>
            <b-dropdown-item
              v-if="ability.can('update', 'invoices') && !data.item.is_audit && data.item.deleted_at == null"
              :to="{ name: 'invoices-edit', params: { id: data.item.id } }">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">修改</span>
            </b-dropdown-item>
            <b-dropdown-item @click="payment(data.item)" v-if="!data.item.is_audit && data.item.deleted_at == null">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">支付</span>
            </b-dropdown-item>
            <b-dropdown-item v-if="ability.can('delete', 'invoices') && data.item.deleted_at == null"
              @click="confirmDelete(data.item.id)">
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">刪除</span>
            </b-dropdown-item>
            <b-dropdown-item
                v-if="ability.can('delete', 'invoices') && data.item.deleted_at"
                @click="confirmRestore(data.item.id)"
              >
                <feather-icon icon="RotateCcwIcon" />
                <span class="align-middle ml-50">恢復</span>
              </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
    </b-overlay>
      <div class="mx-2 mb-2">
        <b-row>
        
          <b-col cols="12" sm="6" class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-start
            ">
            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
              {{ dataMeta.of }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col cols="12" sm="6" class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-end
            ">
            <b-pagination v-model="currentPage" :total-rows="totalInvoices" :per-page="perPage" first-number last-number
              class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item">
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BSidebar,
  BForm,
  BFormGroup,
  BOverlay,
  BFormDatepicker,
  BFormTextarea,
} from "bootstrap-vue";
import vSelect from "vue-select";
import store from "@/store";
import { ref, onUnmounted, onMounted } from "@vue/composition-api";
import { avatarText } from "@core/utils/filter";
import axiosIns from "@/libs/axios";
import invoiceStoreModule from "../invoiceStoreModule";
import useInvoicesList from "./useInvoicesList";
import InvoiceListFilter from "./InvoiceListFilter"

import Ripple from "vue-ripple-directive";
import "quill/dist/quill.core.css";
// eslint-disable-next-line
import "quill/dist/quill.snow.css";
// eslint-disable-next-line
import "quill/dist/quill.bubble.css";

import { quillEditor } from "vue-quill-editor";
import ModalPdf from '@/layouts/components/ModalPdf.vue';
import ModalDocument from '@/views/payment/modal/ModalDocument.vue';

export default {
  data() {
    return {
      pdfUrl: "",
      modalId: '-invoice-list',
      invoice_id: null,
    }
  },
  directives: {
    Ripple,
  },
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    BSidebar,
    BForm,
    BFormGroup,
    BOverlay,
    BFormDatepicker,
    quillEditor,
    InvoiceListFilter,
    ModalPdf,
    BFormTextarea,
    ModalDocument
  },
  computed:{
    invoice_list_name(){
      if(!this.statusFilter){
        return '發票列表';
      }

      return this.statusOptions.find(item => item.value == this.statusFilter).listName;

    }
  },
  methods: {
    downloadDocument(invoice_id)
    {
      this.invoice_id = invoice_id
      this.$bvModal.show(`modal-document${this.modalId}`);
    },
    downloadInvoice(id) {
      this.tableLoading = true;
      this.$store
        .dispatch("invoice/downloadInvoice", { id })
        .then((response) => {
          console.log('response', response)
          const filepdf = new Blob([response.data], {
            type: "application/pdf",
          });
          const fileURL = URL.createObjectURL(filepdf);
          this.pdfUrl = fileURL;
          this.$bvModal.show("modal-pdf-invoice-list");
          this.tableLoading = false;
        })
        .catch((error) => {
          console.log({ error });
          this.tableLoading = false;
        });
    },
    updateSideBar(val) {
      this.isAddNewInvoiceSidebarActive = val;
    },
    resetForm() {
      this.invoice = {
        id: 0,
        application_codes: [],
        payment_method_id: [],
        application_remark: "",
      };
    },
    hide() {
      this.isAddNewInvoiceSidebarActive = false;
    },
    getDate(str) {
      return str.substring(0, 10);
    },
    getNumberFormat(amount) {
      var formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "HKD",
        minimumFractionDigits: 0,
      });
      return formatter.format(amount);
    },
    toggleUpload() {
      this.showUpload = !this.showUpload;
    },
    beforeRemove(file) {
      if (file.url == process.env.VUE_APP_IMAGE_URL + this.invoice.image_path) {
        this.removeExistingPic = true;
        this.invoice.image_path = null;
      }
    },
    onChange(file, fileLists) {
      this.showUpload = !this.showUpload;
      console.log(file.raw);
      this.invoice.file = file.raw;
    },
    payment(item) {
      console.log("payment", item);
      this.invoice.client_code = item.client.client_code;
      this.invoice.total_amount = item.total_amount;
      this.invoice.total_paid = item.total_paid;
      this.invoice.invoice_number = item.invoice_number;
      this.invoice.total_amount = item.total_amount;
      this.invoice.invoice_id = item.id;
      this.invoice.item =
        "The deposit payment of the invoice no. " +
        item.invoice_number +
        " of the total amount of HK$" +
        (item.total_amount - item.total_paid);
      this.invoice.payment_amount = item.total_amount - item.total_paid;
      this.invoice.payment_method_id = 1;
      this.invoice.payment_date = "";
      this.invoice.payment_remark = "";
      this.isAddNewInvoiceSidebarActive = true;
    },
    onSubmit() {
      this.$swal({
        title: "確認儲存?",
        text: "",
        showCancelButton: true,
        confirmButtonText: "儲存",
        cancelButtonText: '取消',
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.loading = true;
          let formData = new FormData();
          formData.append("file", this.invoice.file);
          formData.append("invoice_id", this.invoice.invoice_id);
          formData.append("payment_amount", this.invoice.payment_amount);
          formData.append("item", this.invoice.item);
          formData.append("payment_method_id", this.invoice.payment_method_id);
          formData.append("payment_date", this.invoice.payment_date);
          formData.append("payment_remark", this.invoice.payment_remark);
          formData.append("receipt_template_id", this.invoice.receipt_template_id);
          this.$store
            .dispatch(
              this.INVOICE_STORE_MODULE_NAME + "/addInvoicePayment",
              formData
            )
            .then((response) => {
              this.$swal({
                text: response.data.message,
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
              this.loading = false;
              this.hide();
              //this.$emit("refetch-data");
              this.refetchData()
            })
            .catch((error) => {
              this.loading = false;
              console.log
              if (error.response && error.response.status === 413) {
                this.$swal({
                  text: "文件太大，請選擇較小的文件重試。",
                  icon: "danger",
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                  buttonsStyling: false,
                });
              } else {
                this.$swal({
                  text: JSON.stringify(error.response.data.message),
                  icon: "error",
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                  showClass: {
                    popup: "animate__animated animate__bounceIn",
                  },
                  buttonsStyling: false,
                });
              }
            });
        }
      });
    },
    confirmDelete(id) {
      this.$swal({
        title: "確認刪除?",
        text: "發票及付款記錄將會一拼被刪除。",
        showCancelButton: true,
        confirmButtonText: "刪除",
        cancelButtonText: "取消",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.$store
            .dispatch("invoice/deleteInvoice", { id: id })
            .then((response) => {
              this.$swal({
                text: response.data.message,
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
              this.refetchData();
            })
            .catch((error) => {
              this.$swal({
                text: JSON.stringify(error.response.data.message),
                icon: "error",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
            });
        }
      });
    },
    confirmRestore(id) {
        this.$swal({
          title: "確認刪恢復?",
          showCancelButton: true,
          confirmButtonText: "恢復",
          cancelButtonText: '取消',
          customClass: {
            confirmButton: "btn btn-primary",
            cancelButton: "btn btn-outline-danger ml-1",
          },
          buttonsStyling: false,
        }).then((result) => {
          if (result.value) {
            this.$store
              .dispatch(`${this.INVOICE_STORE_MODULE_NAME}/restoreInvoice`, id)
              .then((response) => {
                this.$swal({
                  text: response.data.message,
                  icon: "success",
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                  showClass: {
                    popup: "animate__animated animate__bounceIn",
                  },
                  buttonsStyling: false,
                });
                this.refetchData();
                this.refetchStatistic();
              })
              .catch((error) => {
                this.$swal({
                  text: JSON.stringify(error.response.data.message),
                  icon: "error",
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                  showClass: {
                    popup: "animate__animated animate__bounceIn",
                  },
                  buttonsStyling: false,
                });
              });
          }
        });
      },
  },
  setup() {
    const INVOICE_STORE_MODULE_NAME = "invoice";
    const isAddNewInvoiceSidebarActive = ref(false);
    const showUpload = ref(true);

    const uploadUrl = ref(process.env.VUE_APP_SERVICE_URL + "/invoice");
    const headerObj = {
      Authorization: "Bearer " + localStorage.getItem("accessToken"),
    };
    const paymentOptions = ref([]);
    const fileList = ref([]);
    const invoice = ref({
      id: 0,
      application_codes: [],
      payment_method_id: [],
      application_remark: "",
    });
    const loading = ref(false);
    const tableLoading = ref(false);
    const blankInvoiceData = {
      id: 0,
      application_codes: [],
      application_remark: "",
    };

    if (!store.hasModule(INVOICE_STORE_MODULE_NAME))
      store.registerModule(INVOICE_STORE_MODULE_NAME, invoiceStoreModule);

    onUnmounted(() => {
      if (store.hasModule(INVOICE_STORE_MODULE_NAME))
        store.unregisterModule(INVOICE_STORE_MODULE_NAME);
    });

    store
      .dispatch(INVOICE_STORE_MODULE_NAME + "/fetchPaymentMethods")
      .then((response) => {
        paymentOptions.value = response.data.paymentmethods;
      })
      .catch((error) => {
        console.log(error.response);
      });

    const {
      fetchInvoices,
      tableColumns,
      perPage,
      currentPage,
      totalInvoices,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refetchData,
      refInvoiceListTable,
      ability,
      userFilter,
      clientFilter,
      dateFromFilter,
      dateToFilter,
      statusFilter,
      statusOptions
    } = useInvoicesList();

    //Filter 
    const userOptions = ref([])
    const clientOptions = ref([])
    const receiptTemplateOptions = ref([])


    store.dispatch('app/fetchOptionList', {user:true, client: true, receipt_template: true})
    .then(response => {
      userOptions.value = response.data.users;
      clientOptions.value = response.data.clients;
      receiptTemplateOptions.value = response.data.receipt_templates;
    })
    .then(error => {
      console.log('Error in fetching User error', error)
    });

    return {
      INVOICE_STORE_MODULE_NAME,
      fetchInvoices,
      tableColumns,
      perPage,
      currentPage,
      totalInvoices,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refetchData,
      refInvoiceListTable,
      ability,
      isAddNewInvoiceSidebarActive,
      invoice,
      loading,
      showUpload,
      uploadUrl,
      headerObj,
      fileList,
      paymentOptions,
      userOptions,
      clientOptions,
      userFilter,
      clientFilter,
      dateFromFilter,
      dateToFilter,
      statusFilter,
      tableLoading,
      statusOptions,
      receiptTemplateOptions
    };
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.badge {
  display: block;
  margin: 5px;
  max-width: 200px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
