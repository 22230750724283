<template>
  <b-card no-body>
    <b-card-header class="pb-50">
      <h5>過濾器</h5>
    </b-card-header>
    <b-card-body>
      <b-row>
        <b-col cols="12" md="2" class="mb-md-0 mb-2">
          <label>發票狀態</label>
          <v-select :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :value="statusFilter" :options="statusOptions"
            label="label" class="w-100" :reduce="(val) => val.value"
            @input="(val) => $emit('update:statusFilter', val)" />
        </b-col>
        <b-col cols="12" md="2" class="mb-md-0 mb-2">
          <label>客戶</label>
          <v-select :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :value="clientFilter" :options="clientOptions"
            label="client_code" class="w-100" :filter="clientSearch" :reduce="(val) => val.id"
            @input="(val) => $emit('update:clientFilter', val)">
            <template #option="{ client_code, company_name_cn, company_name_en }">
              [{{ client_code }}] {{ company_name_cn }} {{ company_name_en }}
            </template>
            <template #selected-option="{ client_code, company_name_cn, company_name_en }">
             [{{ client_code }}] {{ company_name_cn }} {{ company_name_en }}
            </template>

          </v-select>
        </b-col>
        <b-col cols="12" md="2" class="mb-md-0 mb-2">
          <label>創建者</label>
          <v-select :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :value="userFilter" :options="userOptions"
            label="name" class="w-100" :reduce="(val) => val.id" @input="(val) => $emit('update:userFilter', val)" />
        </b-col>
        <b-col cols="12" md="2" class="mb-md-0 mb-2">
          <label>發票日期由</label>
          <b-form-datepicker id="date_from" placeholder="Date From" local="en" :value="dateFromFilter"
            @input="(val) => $emit('update:dateFromFilter', val)" :date-format-options="{
              year: 'numeric',
              month: 'numeric',
              day: 'numeric',
            }" />
        </b-col>
        <b-col cols="12" md="2" class="mb-md-0 mb-2">
          <label>發票日期至</label>
          <b-form-datepicker id="date_to" placeholder="Date To" local="en" :value="dateToFilter"
            @input="(val) => $emit('update:dateToFilter', val)" :date-format-options="{
              year: 'numeric',
              month: 'numeric',
              day: 'numeric',
            }" />
        </b-col>
       
        <b-col cols="12" md="2" class="mb-md-0 mb-2">
          <label></label>
          <div>
            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" size="sm" variant="primary" @click="reset"
              style="height: 40px">
              重設
            </b-button>
          </div>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardBody,
  BRow,
  BCol,
  BFormDatepicker,
  BButton,
} from "bootstrap-vue";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";

export default {
  directives: {
    Ripple,
  },
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    vSelect,
    BFormDatepicker,
    BButton,
  },
  methods: {
    reset() {
      this.$emit("update:userFilter", null);
      this.$emit("update:dateFromFilter", null);
      this.$emit("update:dateToFilter", null);
      this.$emit("update:clientFilter", null);
      this.$emit("update:statusFilter", null);
    },
    clientSearch(options, search) {
      var returnOptions = options.filter(item => {
        return item.client_code.toLowerCase().includes(search.toLowerCase()) || item.company_name_cn.toLowerCase().includes(search.toLowerCase()) || item.company_name_en.toLowerCase().includes(search.toLowerCase())
      })
      return returnOptions
    }
  },
  props: {
    userFilter: {
      type: [Number, null],
      default: null,
    },
    clientFilter: {
      type: [Number, null],
      default: null,
    },
    dateFromFilter: {
      type: [String, null],
      default: null,
    },
    dateToFilter: {
      type: [String, null],
      default: null,
    },
    statusFilter: {
      type: [Number, null],
      default: null,
    },
    userOptions: {
      type: Array,
      required: true,
    },
    clientOptions: {
      type: Array,
      required: true,
    },
    statusOptions: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
